<template>
  <b-card  no-body>
    <b-card-header v-b-toggle.event-prices header-tag="header" role="tab" >
      <div class="text-center">
        EVENT PRICES
        <i class="fas fa-chevron-down" />
      </div>
    </b-card-header>
    <b-collapse id="event-prices" accordion="my-accordion" role="tabpanel" visible>
      <b-card-body id="agenda-info-knowledge-sessions-container">
        <div
          class="event-prices-section"
          v-for="eventPrice in prices"
          :key="eventPrice.id"
        >
          <div>
            <p class="price-text">{{ eventPrice.name }}</p>
            <p class ="price" >{{ eventPrice.price }} {{ currencyCode }}</p>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


export default {
  name: 'PublicEventPrices',
  data() {
    return {
      prices: [],
    };
  },
  async mounted() {
    const { eventId, awardId } = this.$route.params;
    if (eventId) {
      await this.fetchEventPrices(eventId);
      this.prices = [...this.eventPrices];
    } else {
      await this.fetchAwardPrices(awardId);
      this.prices = [...this.awardPrices];
    }
  },
  methods: {
    ...mapActions({
      fetchEventPrices: 'events/fetchEventPrices',
      fetchAwardPrices: 'events/fetchAwardPrices',

    }),
  },
  computed: {
    ...mapGetters({
      eventPrices: 'events/eventPrices',
      userInfo: 'user/fetchUserInfo',
      awardPrices: 'events/awardPrices',
      getEvent: 'events/fetchEvent',
      getAward: 'awards/award',
      participantMembers: 'events/participantMembers',
      isMobileScreen: 'isMobileScreen',
    }),
    currencyCode() {
      return this.getEvent.currency_code || this.getAward.currency_code;
    },
  },
};
</script>

<style scoped>
.event-prices {
  background-color: white;
  margin-bottom:  15px;
}
.event-prices-section div {
  border-top: 1px solid #e1e1e1;
  padding: 14px 5px 14px 8px;
  text-align: center;
}
.event-prices-section div p{
  margin: 0;
}
.price-text {
  float: left;
  margin-left: 33px;
  text-transform: capitalize;
  font-size: 13px;
  color: #615f5f;
}
.price{
  font-size:12px;
  color: #615f5f;
  text-align: right;
  padding-right: 4px;

}
.eventBookButton{
  background-color: #BD9D5F;
  color: #fff;
  border: 1px solid #BD9D5F;
  width: 100%;
  text-align: center;
  height: 37px;
  margin: 1px 0 0;
  border-radius: 0 0 10px 10px;
}
.eventBookButton:hover{
  background-color: #fff;
  color: #BD9D5F;
}
.heading{
  margin: 13px 12px;
  padding-top: 15px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-grey);
}

@media (max-width: 768px) {
  .card {
    border-radius: 0 10px 10px 10px !important;
    overflow: hidden;
  }
  .card>.card-header>div {
    font-size: 13px;
  }
  .card>.collapse>.card-body {
    padding: 0;
  }
  .card > header i {
    position: absolute;
    top: 16px;
    right: 20px;
  }
  header .fa-chevron-up {
    display: inline-block;
  }
  header .fa-chevron-down {
    display: none;
  }
  .card > .collapsed .fa-chevron-down {
    display: inline-block;
  }
  .card > .collapsed .fa-chevron-up {
    display: none;
  }
  .eventBookButton {
    color: #BD9D5F;
    background-color: #fff;
    border: unset;
    border-top: 2px solid #ebebeb;
    font-size: 12px;
  }
  .event-prices-section div p {
    font-size: 12px;
  }
}
</style>
