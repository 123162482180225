<template>
  <div class="accordion" role="">
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle.statistics header-tag="header" role="tab">
        <div class="text-center">
          STATISTICS
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="statistics" accordion="past-event-info" role="tabpanel">
        <b-card-body v-if="event.statistics">
          <img class="event-statistics-img" :src="event.statistics" alt="" />
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle.video-highlights header-tag="header" role="tab">
        <div class="text-center">
          VIDEO HIGHLIGHTS
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="video-highlights" accordion="past-event-info" role="tabpanel" @hidden="pauseVideo">
        <b-card-body>
          <vue-plyr ref="highlightsPlayer">
            <div
              v-if="event.highlights"
              :data-plyr-provider="getHostName(event.highlights)"
              :data-plyr-embed-id="event.highlights"
            ></div>
            <div v-else class="text-center"> There is no video </div>
          </vue-plyr>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle.photo-slider header-tag="header" role="tab">
        <div class="text-center">
          PHOTO SLIDER
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="photo-slider" accordion="past-event-info" role="tabpanel">
        <b-card-body>
              <b-carousel v-if="images.length"
                id="carousel-1"
                :interval="10000"
                indicators
                background="#EBEBEB"
                :controls="true"
                style="text-shadow: 1px 1px 2px #aaa; padding:1px"
              >
                <b-carousel-slide
                class="slide-img"
                  v-for="(image, index) in images"
                  :key="index"
                  :img-src="image"
                >
                </b-carousel-slide>
              </b-carousel>

          <div v-else class="text-center"> There is no image </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle.post-event-guide header-tag="header" role="tab">
        <div class="text-center">
          POST-EVENT GUIDE
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="post-event-guide" accordion="past-event-info" role="tabpanel">
        <b-card-body>
          <div v-if="event.post_guide" class="media-guide-area">
            <pdf
              :src="event.post_guide"
              :page="1"
              :resize="true"
              style="width:100%;"
            />
            <a
              :href="event.post_guide"
              target="_blank"
              :download="`Post_Event_Guide_${eventName(event.name)}`"
            >
              <div class="media-download">
                Post_Event_Guide_{{eventName(event.name)}}{{event.post_guide.slice(-4)}}
              </div>
            </a>
          </div>
          <p v-else class="text-center">There is no Guide file</p>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import InfoAfterEvent from '../../../utils/mixins/InfoAfterEvent';

export default {
  mixins: [InfoAfterEvent],
  data() {
    return {
      player: null,
    };
  },
  methods: {
    pauseVideo() {
      this.player.pause();
    },
  },
  mounted() {
    this.player = this.$refs.highlightsPlayer.player;
  },
};
</script>

<style scoped>
.event-high-img {
  width: 95px;
  margin: 10px;
  height: 65px;
  object-fit: cover;
  cursor: pointer;
}
.event-statistics-img {
  width: 100%;
  margin: 10px;
  object-fit: cover;
}
.media-download {
  background: #fff;
  color: #BD9D5F;
  text-align: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  font-size: 11px;
  border-top: 2px solid #EBEBEB;
}
.media-guide-area {
  margin-top: 4px;
}
.media-download:hover {
  opacity: 0.5;
  transition: 0.3s;
}

.slide-img >>> img{
  height: 300px;
  object-fit: contain;
}
.card {
  border-radius: 0 10px 10px 10px !important;
  overflow: hidden;
}
.card>.card-header>div {
  font-size: 13px;
}
.card>.collapse>.card-body {
  padding: 0;
}
.card > header i {
  position: absolute;
  top: 16px;
  right: 20px;
}
header .fa-chevron-up {
  display: inline-block;
}
header .fa-chevron-down {
  display: none;
}
.card > .collapsed .fa-chevron-down {
  display: inline-block;
}
.card > .collapsed .fa-chevron-up {
  display: none;
}
</style>
