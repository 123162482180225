<template>
  <div v-if="isActiveSection()">
    <PublicEventPrices v-if="isActivePrices()" class="event-prices" />
    <InfoBeforeEvent v-if="!isMobileScreen && isEndedEvent()" />
    <MobileInfoBeforeEvent v-if="isMobileScreen && isEndedEvent()"/>
    <InfoAfterEvent v-else-if="!isMobileScreen && !isEndedEvent()" />
    <MobileInfoAfterEvent v-else-if="isMobileScreen && !isEndedEvent()" />
  </div>
  <div v-else style="text-align: center; color: #bd9d5f">The section is not available now and coming soon...</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import InfoBeforeEvent from './InfoBeforeEvent.vue';
import InfoAfterEvent from './InfoAfterEvent.vue';
import MobileInfoBeforeEvent from './MobileInfoBeforeEvent.vue';
import PublicEventPrices from '../../../components/advertisement/PublicEventPrices.vue';
import MobileInfoAfterEvent from './MobileInfoAfterEvent.vue';

export default {
  name: 'Info',
  components: {
    InfoBeforeEvent,
    PublicEventPrices,
    InfoAfterEvent,
    MobileInfoBeforeEvent,
    MobileInfoAfterEvent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
      isMobileScreen: 'isMobileScreen',
    }),

  },
  methods: {
    ...mapActions({
      fetchEvent: 'events/fetchEvent',
    }),
    isEndedEvent() {
      return this.event.end_date >= moment(new Date().toUTCString()).format('YYYY-MM-DD');
    },
    isActiveSection() {
      return this.event && this.event.available_sections ? this.event.available_sections.includes('info') : false;
    },
    isActivePrices() {
      return this.event && this.event.available_sections.includes('prices') && this.isMobileScreen && this.$route.name === 'PublicInfo';
    },

  },
};
</script>

<style scoped>
.agenda-info-heading {
  background-color: #ffffff;
  border-radius: 0 10px 10px 10px;
}
.agenda-info-reasons-heading {
  padding: 6px 1px 19px 1px;
  border-bottom: 1.4px solid #ebebeb;
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat;
  margin-top: 8px;
}
.sponsor-request-text {
  text-align: center;
  font-size: 13px;
  color: #333333;
  padding: 4px 15px 1px 15px;
}
.sponsor-request-text > p {
  font-size: 13px;
  text-transform: initial;
}
.contact-btn {
  border: unset;
  margin: 16px 1px 1px 23px;
  background: #bd9d5f;
  color: white;
  padding: px 1px 6px 0px;
  font-size: 12px;
}
.right-photo {
  margin-top: -35px;
}
.activites > img {
  width: 140px;
  border-right: 1px solid #d6d6d6;
  padding: 11px 2px 3px 11px;
}
.agenda-info-reasons-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  object-fit: scale-down;
}
.kownledge-heading {
  background-color: white;
}
.agenda-info-knowledge-img {
  width: 90px;
  height: 75px;
  -o-object-fit: contain;
  object-fit: scale-down;
}
.agenda-info-session-btn {
  width: 92px;
  height: 20px;
  font-size: 10px;
  background: #bd9d5f;
  color: white;
  border: unset;
  margin-left: -1px;
}
.agenda-info-session-btn-second {
  width: 92px;
  height: 20px;
  font-size: 10px;
  background: #ffffff;
  color: #bd9d5f;
  border: 1px solid;
  margin-left: -1px;
}
.agenda-info-res-text {
  position: relative;
}
.agenda-info-res-text > p {
  position: absolute;
  top: 34px;
  background: #55555591;
  text-align: center;
  width: 289px;
  color: white;
  padding: 8px 0px;
  text-transform: uppercase;
  font-size: 13px;
}
.agenda-info-strategic,
.agenda-info-strategic-slide,
.agenda-info-knowledge-heading {
  background-color: #ffffff;
  border-radius: 0 10px 0 0;
}
#agenda-info-knowledge-sessions-container {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 17px;
  border-radius: 0 0 10px 10px;
}
.agenda-info-reason-image {
  width: 289px;
  height: 100px;
  object-fit: cover;
}
.agenda-info-parallel-img {
  vertical-align: middle;
  border-style: none;
  width: 142px;
  border: 1px solid #e1dfdf;
  margin: 15px 0px 0px 20px;
  width: 80px;
  height: 80px;
  object-fit: scale-down;
}
.agenda-info-parallel-btn {
  float: right;
  background: white;
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  font-size: 12px;
  padding: 3px 10px;
}
.agenda-info-parallel-p {
  color: #514f4f;
  font-size: 13px;
  margin-top: 19px;
}
</style>
<style>
.agenda-info-vueper-slides .vueperslides__bullets {
  display: none !important;
}
.agenda-info-vueper-slides
  .vueperslides__arrows--outside
  .vueperslides__arrow--prev {
  left: 0em;
  outline: none;
  top: 48px;
  font-size: 11px;
}
.agenda-info-vueper-slides
  .vueperslides__arrows--outside
  .vueperslides__arrow--next {
  right: -0em;
  outline: none;
  top: 52px;
  font-size: 11px;
}
</style>
