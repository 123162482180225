<template>
  <div class="accordion" role="">
    <b-card v-if="event.description" no-body class="mb-1">
      <b-card-header v-b-toggle.event-info header-tag="header" role="tab">
        <div class="text-center">
          Info
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="event-info" accordion="my-accordion" visible role="tabpanel">
        <b-card-body>
          <div>
            <p class="single-info-nested-text" v-html="event.description"></p>
          </div>
          <a href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com">
            <button class="contact-btn">Contact us</button>
          </a>
        </b-card-body>
      </b-collapse>
    </b-card>

    <PreviousEdition v-if="event.previous_edition" />
    <EventPrices v-if="isActivePrices()" class="event-prices" />

    <b-card v-if="reasons.length" no-body class="mb-1">
      <b-card-header v-b-toggle.event-reasons header-tag="header" role="tab">
        <div class="text-center">
          Reasons to Attend
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="event-reasons" accordion="my-accordion" role="tabpanel">
        <b-card-body class="agenda-info-reasons-images">
          <div
            class="agenda-info-res-text"
            v-for="reason in reasons"
            :key="reason.id"
            :style="`background-image: url(${reason.image})`"
          >
            <p class="reason-title">{{ reason.title }}</p>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card v-if="sponsors.length" no-body class="mb-1">
      <b-card-header v-b-toggle.event-co-hosted-sponsors header-tag="header" role="tab">
        <div class="text-center">
          Strategic co-hosted sponsors
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="event-co-hosted-sponsors" accordion="my-accordion" role="tabpanel">
        <b-card-body class="co-hosted-sponsors">
          <div v-for="({ details, id }) in sponsors" :key="id" class="mb-1">
            <img class="agenda-info-knowledge-img" :src="details.logo" alt="" />
          </div>
        </b-card-body>
        <router-link :to="{ name: 'BecomeSponsorEvent', params: { eventId: event.id }}">
          <button class="agenda-info-session-btn">BECOME A SPONSOR</button>
        </router-link>
      </b-collapse>
    </b-card>
    <b-card v-if="session.length" no-body class="mb-1">
      <b-card-header v-b-toggle.event-sessions header-tag="header" role="tab">
        <div class="text-center">
          Knowledge sharing sessions
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="event-sessions" accordion="my-accordion" role="tabpanel">
        <b-card-body id="agenda-info-knowledge-sessions-container">
          <div v-for="s in session" :key="s.id" class="mb-1">
            <img class="agenda-info-knowledge-img" :src="s.icon" alt="" />
          </div>
        </b-card-body>
        <button class="agenda-info-session-btn" @click="goToAgenda">SEE THE AGENDA</button>
      </b-collapse>
    </b-card>
    <b-card v-if="parallelEvents.length" no-body class="mb-1">
      <b-card-header v-b-toggle.event-parallel header-tag="header" role="tab">
        <div class="text-center">
          Parallel events
          <i class="fas fa-chevron-up" />
          <i class="fas fa-chevron-down" />
        </div>
      </b-card-header>
      <b-collapse id="event-parallel" accordion="my-accordion" role="tabpanel">
        <b-card-body class="agenda-info-reasons-images">
          <div
            class="agenda-info-res-text"
            v-for="parallelEvent in parallelEvents"
            :key="parallelEvent.id"
            :style="`background-image: url(${parallelEvent.details.banner}); position:relative`"
          >
            <router-link
              :to="{ name: 'standardEvent', params: { eventId: getURL(parallelEvent.parallel_event) }}"
              class="parallel-event-link"
            >
            </router-link>
          </div>
        </b-card-body>
        <router-link :to="{ name: 'ccAllEvents' }" exact>
          <button class="agenda-info-session-btn">GO TO EVENT CALENDAR</button>
        </router-link>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import PreviousEdition from '../../../components/EventList/PreviousEdition.vue';
import EventPrices from '../../../components/advertisement/EventPrices.vue';
import InfoBeforeEvent from '../../../utils/mixins/InfoBeforeEvent';

export default {
  components: {
    PreviousEdition,
    EventPrices,
  },
  mixins: [InfoBeforeEvent],
  methods: {
    isActivePrices() {
      return this.event.available_sections.includes('prices');
    },
  },
};
</script>

<style scoped>

.reason-title{
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  background: #55555591;
  color: white;
  font-size: 18px;
}
.single-info-nested-text {
  text-align: center;
  font-size: 11px;
  color: #333333;
  padding: 10px 5px;
  word-wrap: break-word;
}
.single-info-nested-text > p {
  font-size: 11px;
  text-transform: initial;
}
.contact-btn {
  border: 1px solid #BD9D5F;
  margin: 8px 0 12px 12px;
  background: #FFF;
  color: #BD9D5F;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 0 10px 10px 10px;
}
.agenda-info-reasons-images {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  object-fit: fill;
  border-bottom: 2px solid #ebebeb;
}
.agenda-info-knowledge-img {
  width: 65px;
  height: 65px;
  object-fit: contain;
}
.agenda-info-session-btn {
  width: 100%;
  height: 44px;
  font-size: 12px;
  background: #fff;
  color: #BD9D5F;
  border: unset;
}
.agenda-info-res-text {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
#agenda-info-knowledge-sessions-container,
.co-hosted-sponsors {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  padding: 8px !important;
  border-bottom: 1px solid #ebebeb;
}
#agenda-info-knowledge-sessions-container > div,
.co-hosted-sponsors > div {
  border: 1px solid #bdbdbd;
  padding: 4px;
  max-width: 75px;
  align-self: center;
  justify-self: center;
}
.co-hosted-sponsors > div {
  border: unset;
}
.parallel-event-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.single-info-nested-text >>> p img {
  max-width: 350px;
  max-height: 200px;
  object-fit: cover;
}
.single-info-nested-text >>> iframe {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.card {
  border-radius: 0 10px 10px 10px !important;
  overflow: hidden;
}
.card>.card-header>div {
  font-size: 13px;
}
.card>.collapse>.card-body {
  padding: 0;
}
.card > header i {
  position: absolute;
  top: 16px;
  right: 20px;
}
header .fa-chevron-up {
  display: inline-block;
}
header .fa-chevron-down {
  display: none;
}
.card > .collapsed .fa-chevron-down {
  display: inline-block;
}
.card > .collapsed .fa-chevron-up {
  display: none;
}
@media (max-width: 450px) {
  #agenda-info-knowledge-sessions-container,
  .co-hosted-sponsors {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
    padding: 6px !important;
  }
  #agenda-info-knowledge-sessions-container > div,
  .co-hosted-sponsors > div {
    padding: 4px;
    max-width: 70px;
  }
  .agenda-info-knowledge-img {
    width: 60px;
    height: 60px;
  }
}
</style>
